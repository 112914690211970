/** @jsx jsx */
import { jsx } from "theme-ui"
import Logo from "./logo"
import { graphql, useStaticQuery } from "gatsby"
import Navigation from "./navigation"
import Theme from "./theme"
import Search from "./search"
import Sitedata from "../util/site.json"
import site from "../util/site.json"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      siteSearchIndex {
        index
      }
    }
  `)

  const showLogo = site.meta.showLogo
  const showTitle = site.meta.showTitle

  return (
    <header sx={headerStyles.siteHeader}>
      <div sx={headerStyles.container}>
        {showLogo === true || showTitle === true ? <Logo /> : ""}
        <div sx={headerStyles.navMenuBigScreen}>
          <Navigation />
        </div>
        <div sx={headerStyles.navIcons}>
          <div sx={headerStyles.shareIcon}>
            {Sitedata.meta.searchVisibility === "YES" ? (
              <Search searchIndex={data.siteSearchIndex.index} />
            ) : (
              ""
            )}
          </div>
          <div sx={headerStyles.navMenu}>
            <Navigation />
          </div>
        </div>

        {Sitedata.meta.searchVisibility === "YES" ||
        Sitedata.meta.darkModeVisibility === "YES" ? (
          <div sx={headerStyles.theme}>
            {Sitedata.meta.searchVisibility === "YES" ? (
              <Search searchIndex={data.siteSearchIndex.index} />
            ) : (
              ""
            )}
            {Sitedata.meta.darkModeVisibility === "YES" ? <Theme /> : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    </header>
  )
}

export default Header

const headerStyles = {
  siteHeader: {
    bg: "header.bgColor",
    position: "sticky",
    top: "0",
    zIndex: "11111",
  },
  navMenuBigScreen: {
    display: ["none", "none", "none", "block"],
  },
  navMenu: {
    display: ["block", "block", "block", "none"],
    ".site-menu": {
      display: "none",
    },
  },
  container: {
    variant: "variants.container",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pl: ["20px", "20px", "40px", "40px", "80px"],
    pr: ["0", "0", "20px", "20px", "62px"],
  },
  navIcons: {
    display: ["flex", "flex", "flex", "none"],
    float: ["right", "right", "inherit"],
  },
  shareIcon: {
    display: ["block", "block", "block", "none"],
    ".search-container": {
      mr: "0px !important",
    },
  },
  theme: {
    display: ["none", "none", "none", "flex"],
  },
}
