/** @jsx jsx */
import { jsx } from "theme-ui"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, className }) => {
  return (
    <div sx={layoutStyles.base}>
      <Header />
      <main className={"container " + className}>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout

const layoutStyles = {
  base: {
    bg: "site.background",
    fontFamily: "body",
    fontWeight: "body",
    lineHeight: "body",
    code: {
      fontFamily: "monospace",
    },
    b: {
      fontWeight: "heading",
    },
    a: {
      textDecoration: "none",
    },
    "h1, h2": {
      fontFamily: "heading",
      fontWeight: "heroHeading",
      lineHeight: "heading",
    },
    h1: {
      fontSize: [6, 7, 8, 8, 9],
    },
    h2: {
      fontSize: [4, 5, 6],
    },
    "h3, h4, h5, h6": {
      fontFamily: "body",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    ".section-heading": {
      fontFamily: "body",
      fontWeight: "heading",
      lineHeight: "heading",
    },
  },
}
